import React, { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import LeftLogo from "../../../components/LeftLogo";
import PTInput from "../../../components/GenericComponents/PTInput";
import PTButton from "../../../components/GenericComponents/PTButton";
import { RFF_SIGNUP_API } from "../../../constants/APIs";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import { startLoading, stopLoading } from "../../../utils/utility/loader";

export default function Signup() {
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      fullName: "",
      companyName: "",
      companyEmail: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().trim().required("Required"),
      companyName: Yup.string().trim().required("Required"),
      companyEmail: Yup.string()
        .trim()
        .email("This email is not a valid format.")
        .required("Required"),
      password: Yup.string()
        .trim()
        .required("Required")
        .min(6, "Password is too short - should be 6 chars minimum.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
          "Password has contain minimum 6 characters (includes 1 Upper, 1 Special, 1 Number)"
        ),
      confirmPassword: Yup.string()
        .trim()
        .required("Required")
        .oneOf([Yup.ref("password"), null], "Password don't match"),
    }),
    onSubmit: (values, { resetForm }) => {
      userRegister(
        {
          name: values.fullName,
          email: values.companyEmail,
          companyName: values.companyName,
          password: values.password,
        },
        resetForm
      );
    },
  });

  // API call for signup
  const userRegister = (userData, resetForm) => {
    startLoading();
    axios
      .post(RFF_SIGNUP_API, userData)
      .then((res) => {
        resetForm({ values: "" });
        handleSuccess(res);
        stopLoading();
        navigate("/thanksforinterest");
      })
      .catch((error) => {
        handleError(error);
        setLoginError(error?.response?.data?.message);
        stopLoading();
      });
  };

  return (
    <div>
      <div className="w-screen flex proptech-layout-wrapper flex-wrap">
        <div className="w-full md:w-3/5">
          <LeftLogo />
        </div>
        <div className="md:min-h-screen w-full md:w-2/5 items-center inline-flex justify-center relative">
          <form onSubmit={formik.handleSubmit}>
            <div className="wrapper-container">
              <div className="text-wrapper">
                <h2>Sign up</h2>
                <p>
                  We take security seriously. We'll verify your details once
                  completed, and be in contact via email, ASAP.
                </p>
              </div>
              {loginError ? (
                <div className="danger-login">
                  <span>{loginError}</span>
                </div>
              ) : null}
              <div className="form-wrapper">
                <div
                  className={
                    "form-group " +
                    (formik.touched.fullName && formik.errors.fullName
                      ? "error-parent"
                      : "") +
                    (!formik.errors.fullName && formik.values.fullName
                      ? "success-attempt"
                      : "")
                  }
                >
                  <PTInput
                    id="fullName"
                    name="fullName"
                    label="Full name"
                    placeholder="Full name"
                    className="form-control"
                    handleOnChangeEvent={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullName}
                  />
                  {formik.touched.fullName && formik.errors.fullName && (
                    <span className="error-txt">{formik.errors.fullName}</span>
                  )}
                </div>
                <div
                  className={
                    "form-group " +
                    (formik.touched.companyName && formik.errors.companyName
                      ? "error-parent"
                      : "") +
                    (!formik.errors.companyName && formik.values.companyName
                      ? "success-attempt"
                      : "")
                  }
                >
                  <PTInput
                    id="companyName"
                    name="companyName"
                    label="Company name"
                    placeholder="Company name"
                    className="form-control"
                    handleOnChangeEvent={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyName}
                  />
                  {formik.touched.companyName && formik.errors.companyName && (
                    <span className="error-txt">
                      {formik.errors.companyName}
                    </span>
                  )}
                </div>
                <div
                  className={
                    "form-group " +
                    (formik.touched.companyEmail && formik.errors.companyEmail
                      ? "error-parent"
                      : "") +
                    (!formik.errors.companyEmail && formik.values.companyEmail
                      ? "success-attempt"
                      : "")
                  }
                >
                  <PTInput
                    id="companyEmail"
                    name="companyEmail"
                    label="Company email"
                    placeholder="Company email"
                    className="form-control"
                    handleOnChangeEvent={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyEmail}
                  />
                  {formik.touched.companyEmail &&
                    formik.errors.companyEmail && (
                      <span className="error-txt">
                        {formik.errors.companyEmail}
                      </span>
                    )}
                </div>
                <div
                  className={
                    "form-group " +
                    (formik.touched.password && formik.errors.password
                      ? "error-parent"
                      : "") +
                    (!formik.errors.password && formik.values.password
                      ? "success-attempt"
                      : "")
                  }
                >
                  <PTInput
                    id="password"
                    name="password"
                    label="Password"
                    placeholder="Password"
                    className="form-control"
                    type="password"
                    handleOnChangeEvent={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <span className="error-txt">{formik.errors.password}</span>
                  )}
                </div>
                <div className="password-requirement">
                  <p>Password must contain at least:</p>
                  <ul>
                    <li>1 uppercase letter</li>
                    <li>1 number</li>
                    <li>1 special character</li>
                  </ul>
                </div>
                <div
                  className={
                    "form-group " +
                    (formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                      ? "error-parent"
                      : "") +
                    (!formik.errors.confirmPassword &&
                    formik.values.confirmPassword
                      ? "success-attempt"
                      : "")
                  }
                >
                  <PTInput
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Confirm password"
                    placeholder="Confirm password"
                    className="form-control"
                    type="password"
                    handleOnChangeEvent={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                  />
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword && (
                      <span className="error-txt">
                        {formik.errors.confirmPassword}
                      </span>
                    )}
                </div>
              </div>
              <PTButton
                label={"Sign up"}
                type="submit"
                className="normal-case fill-blue btn"
              />
              <div>
                <div className="not-have-account">
                  <span>
                    Already got an account?
                    <span
                      className="font-bold text-[#111D43] cursor-pointer"
                      onClick={() => (window.location.href = "/login")}
                    >
                      &nbsp; Log in
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
