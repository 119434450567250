import React from "react";
import { useSelector } from "react-redux";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMoreRounded } from "@material-ui/icons";
import { getRFFLocalStorageUserData } from "../../../../utils/session";
import CompanyLogo from "../../CompanyLogo";
import "./keyAttributesStyles.scss";
import { Store } from "../../../../store/configureStore";
import { Actions } from "../../../../store/actions";
import { useNavigate } from "react-router-dom";

export default function KeyAttributes() {
  // Store
  const { keyAttributesDetails } = useSelector(
    (state) => state.rffProptechDetails
  );
  const { rffProjectTabData } = useSelector((state) => state.rffProptechs);

  const navigate = useNavigate();

  const userData = getRFFLocalStorageUserData() || null;

  const goToProjectPage = () => {
    navigate("/dashboard");
    Store.dispatch({ type: Actions.RFFProptechs.RFFSetActiveTab, payload: 3 });
  };

  return (
    <>
      {keyAttributesDetails ? (
        <div className="raise-detail-wrapper-place">
          <div>
            <h2 className="border-none mb-2">Key attributes</h2>
            {userData !== null ? (
              <div className="bg-[#23305B] rounded-2xl p-4 gap-4 flex  items-center mb-2">
                {userData?.company && (
                  <div className="flex gap-4 flex-wrap items-center">
                    <div
                      className="bg-white h-[58px] flex items-center overflow-hidden justify-center w-[58px] border border-[#C9CDD5] rounded-xl"
                      onClick={goToProjectPage}
                    >
                      <CompanyLogo
                        companyLogo={
                          rffProjectTabData?.companyOtherDetails?.companyLogo ||
                          userData?.company?.companyLogo
                        }
                        name={
                          rffProjectTabData?.companyOtherDetails?.rffCompany
                            ?.name || userData?.company?.name
                        }
                      />
                    </div>
                    <div>
                      <h2 className="border-none  leading-[18px] m-0 text-base !pb-2 ">
                        {userData?.company?.name}
                      </h2>
                      <p className="text-[#C9CDD5] text-xs leading-[18px]">
                        Key attributes of criteria
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <div className="raise-item heading-key-attributes">
            {keyAttributesDetails?.projectAssesmentCriteria?.length !== 0 ? (
              <>
                {keyAttributesDetails?.projectAssesmentCriteria?.map(
                  (item, idx) => (
                    <Accordion
                      elevation={0}
                      disableGutters
                      sx={{
                        "&:before": {
                          display: "none",
                          background: "#111D43",
                        },
                        border: "none",
                        overflow: "hidden",
                        wordWrap: "break-word !important",
                      }}
                      key={idx}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreRounded />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          background: "#111D43",
                          padding: "0px 8px",
                        }}
                      >
                        <div
                          className="text-xs attributes-item leading-4 flex "
                          style={{ wordBreak: "break-word" }}
                        >
                          <h2 className="!text-xs leading-[18px]">
                            {item?.heading}
                          </h2>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          background: "#111D43",
                          border: "none",
                          paddingLeft: "28px",
                        }}
                      >
                        <p className="text-xs leading-[18px] text-[#C9CDD5]">
                          {item?.value}
                        </p>
                      </AccordionDetails>
                    </Accordion>
                  )
                )}
              </>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
}
