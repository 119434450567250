import { useState } from "react";
import { Box, CircularProgress, Divider, Modal } from "@mui/material";
import Info from "../../../assets/Icons/info.svg";
import TickIcon from "../../../assets/Icons/ic-tickmark-white.svg";
import CrossIcon from "../../../assets/Icons/ic-cross-white.svg";
import PTButton from "../../../components/GenericComponents/PTButton";
import { ChangePlanModalStyle, features } from "../../constants";
import Loader from "../../../components/Loading";
import "./change-plan.scss";

function ChangePlan({
  loading,
  openChangePlanModal,
  closeChangePlanModal,
  plans,
  currentPlan,
  handleSuccess,
}) {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isChangePlan, setIsChangePlan] = useState(currentPlan ? false : true);

  const selectPlan = (planId) => {
    setSelectedPlan(planId);
  };

  return (
    <>
      {loading && <Loader />}
      <Modal open={openChangePlanModal} onClose={closeChangePlanModal}>
        <Box sx={ChangePlanModalStyle}>
          <div className="overflow-auto">
            {!plans.length ? (
              <div className="w-full h-full flex justify-center items-center">
                <CircularProgress />
              </div>
            ) : (
              <div className="change-plan-wrapper">
                {plans.length > 0 && (
                  <div className="plan-wrapper-place flex">
                    <div className="packages-wrapper flex-1">
                      <div className="package-title">RFF Packages</div>
                      <div className="font-semibold my-6">Features</div>
                      <div className="feature-list">
                        <Divider />
                        {features.map((feat) => {
                          const { label } = feat;
                          return (
                            <>
                              <div
                                className="feature-info flex items-center justify-between"
                                style={{
                                  marginTop: "1.1rem",
                                  marginBottom: "0.75rem",
                                }}
                              >
                                <p className="pr-4 leading-tight font-['Poppins'] text-xs text-[#111D43]">
                                  {label}
                                </p>
                                {/* <img
                                  src={Info}
                                  alt="Info"
                                  className="w-5 h-5"
                                /> */}
                              </div>
                              <Divider />
                            </>
                          );
                        })}
                      </div>
                    </div>
                    {plans.map((details) => {
                      if (
                        isChangePlan ||
                        (currentPlan && currentPlan === details.id)
                      ) {
                        return (
                          <div
                            className={`plan-details-wrapper max-w-max ml-0 ${
                              selectedPlan === details.id ? "selected-plan" : ""
                            }`}
                          >
                            <div
                              className={`plan-title ${details.name.toLowerCase()}`}
                            >
                              {details.name}
                            </div>
                            <div
                              className="font-semibold my-6 text-center"
                              style={{
                                width: "69%",
                                fontSize: 14,
                                height: 23,
                                margin: 0,
                              }}
                            >
                              {/* {details.price ? (
                                <>
                                  ${details.price}/
                                  <span className="text-xs">month</span>
                                </>
                              ) : (
                                "Free"
                              )} */}
                              {details.desc}
                            </div>
                            <div className="feature-list">
                              <Divider />
                              {features.map((feat) => {
                                const { key, icon } = feat;
                                return (
                                  <>
                                    {icon ? (
                                      <div className="my-[10px] text-center">
                                        {details?.[key] ? (
                                          <span className="w-5 h-5 bg-[#39B54A] rounded-full inline-flex items-center justify-center">
                                            <img src={TickIcon} alt="Info" />
                                          </span>
                                        ) : (
                                          <span className="w-5 h-5 bg-[#FF4100] rounded-full inline-flex items-center justify-center">
                                            <img
                                              src={CrossIcon}
                                              alt="Cross Icon"
                                            />
                                          </span>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="my-[16px] text-center">
                                        <p className="leading-tight font-['Poppins'] text-xs text-[#111D43]">
                                          {details?.[key] || "Nil"}
                                        </p>
                                      </div>
                                    )}
                                    <Divider />
                                  </>
                                );
                              })}
                            </div>
                            <div
                              className={`text-center my-6 mx-8 button-${details.name.toLowerCase()}`}
                            >
                              {!isChangePlan && currentPlan ? (
                                <PTButton
                                  label="Change Plan"
                                  className="fill-gray btn"
                                  handleOnClickEvent={() =>
                                    setIsChangePlan(true)
                                  }
                                />
                              ) : currentPlan === details.id ? (
                                <PTButton
                                  label="Current Plan"
                                  className="btn"
                                  variant={"text"}
                                  disabled
                                />
                              ) : (
                                <PTButton
                                  label="Select"
                                  className="fill-gray btn"
                                  handleOnClickEvent={() =>
                                    selectPlan(details.id)
                                  }
                                />
                              )}
                            </div>
                          </div>
                        );
                      }
                      return <></>;
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="mt-5 proptech-stepper">
            <div className="flex justify-end">
              <PTButton
                variant="contained"
                label={
                  <span className="!font-semibold !h-5 !text-sm !leading-5 !text-center !not-italic !w-20 !text-[#111D43]">
                    Cancel
                  </span>
                }
                className="mr-4 fill-gray btn !border-[#111d43] !bg-[#FFFFFF]"
                handleOnClickEvent={closeChangePlanModal}
              />
              <PTButton
                variant="contained"
                label="Change"
                disabled={!(selectedPlan > 0)}
                className="fill-blue btn small-size disabled:text-[#888] disabled:bg-[#DDD]/[0.6]"
                handleOnClickEvent={() => handleSuccess(selectedPlan)}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ChangePlan;
