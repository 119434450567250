import React from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export default function Chart(props) {
  const {
    items,
    labels,
    label,
    step,
    minimumValue,
    maximumValue,
    maintainAspectRatio,
  } = props;
  const data = {
    labels: labels
      ? labels
      : ["Data 1", "Data 2", "Data 3", "Data 4", "Data 5", "Data 6"],
    datasets: [
      {
        label: label || "",
        data: items ? items : [2.7, 2.5, 4.5, 0.7, 1.6, 4.3],
        borderWidth: 1,
        pointBorderColor: "#ffffff",
        pointBorderWidth: 2,
        backgroundColor: "#5FD0DF33",
        borderColor: "#5FD0DF",
        pointRadius: 2,
        pointBackgroundColor: "#000000",
      },
    ],
  };

  return (
    <Radar
      data={data}
      options={{
        responsive: true,
        maintainAspectRatio: !maintainAspectRatio,
        spacing: 0,
        plugins: {
          legend: {
            display: false,
          },
        },

        scale: {
          ticks: {
            display: false,
            min: minimumValue || 1,
            max: 10,
            stepSize: step || 1,
            showLabelBackdrop: false,
            backdropColor: "rgba(203, 197, 11, 1)",
          },

          angleLines: {
            color: "rgba(255, 255, 255, .3)",
            lineWidth: 1,
          },
          gridLines: {
            circular: false,
          },
        },
        scales: {
          r: {
            ticks: {
              display: false,
            },
            pointLabels: {
              font: {
                size: 12,
                color: "#111D43",
                family: "Poppins",
              },
            },
            max: maximumValue || undefined,
            min: minimumValue || 0,
          },
        },
      }}
    />
  );
}
