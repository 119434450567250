import React, { useEffect, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ArrowUpIcon from "../../../assets/Icons/admin-arrow-up.svg";
import ArrowDownIcon from "../../../assets/Icons/admin-arrow-down.svg";
import PTButton from "../PTButton";
import "./PTSelect.scss";
import { Store } from "../../../store/configureStore";
import { Actions } from "../../../store/actions";
import { useSelector } from "react-redux";
import { sortByDefaultOptionPlace } from "../../../rff/constants";

const PaperProps = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    borderRadius: "8px",
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    ".MuiCheckbox-root": {
      padding: "0 10px 0 0",
      color: "#DADADA",
      "&.Mui-checked": {
        color: "#009BE2",
      },
    },
    ".MuiTypography-root": {
      color: "#111D43",
      fontFamily: "Poppins",
      fontWeight: "600",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: 1,
    },
    ".MuiMenuItem-root": {
      padding: "15px 19px",
      "&.Mui-selected": {
        background: "transparent",
      },
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      left: "50%",
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translate(-50%, -50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

export default function PTSelect(props) {
  //  ***** props *****
  const {
    className,
    id,
    optionValue,
    labelValue,
    multiple,
    checked,
    Isbutton,
    handlefilterData,
    labelId,
  } = props;

  const MenuProps = {
    id: "dropdown-" + id,
    PaperProps: PaperProps,
  };
  // State
  const [personName, setPersonName] = useState(
    labelId === "orderBy"
      ? [{ id: "createdAt", name: "Default", labelId: "orderBy" }]
      : []
  );
  const [filterData, setFilterData] = useState([]);
  const [sortOrder, setSortOrder] = useState("DESC");
  const [sortBy, setSortBy] = useState("createdAt");
  const [closeOption, setCloseOption] = useState(false);

  //Store
  const { filter, orderBy, order } = useSelector((state) => state.rffProptechs);

  // Handle select change value for state and store
  const handleChange = async (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
    setFilterData(value);
    if (value?.labelId) {
      handlefilterData(
        {
          [labelId]: filterData.length
            ? filterData?.map((val) => val?.id)
            : undefined,
        },
        value?.id,
        sortOrder
      );
      setSortBy(value?.id);
      await Store.dispatch({
        type: Actions.RFFProptechs.RFFSetOrder,
        payload: sortOrder,
      });
      Store.dispatch({
        type: Actions.RFFProptechs.RFFSetOrderBy,
        payload: value?.id,
      });
    }
  };

  const handleClearFilter = () => {
    setPersonName([]);
    setFilterData([]);
    handlefilterData({ ...filter, [labelId]: undefined });
    setCloseOption(false);
  };

  const handleSort = (e) => {
    e.stopPropagation();
    setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    handlefilterData(
      {
        [labelId]: filterData.length
          ? filterData?.map((val) => val?.id)
          : undefined,
      },
      sortBy,
      sortOrder === "DESC" ? "ASC" : "DESC"
    );
    Store.dispatch({
      type: Actions.RFFProptechs.RFFSetOrder,
      payload: sortOrder === "DESC" ? "ASC" : "DESC",
    });
  };

  const applyFilteredData = () => {
    handlefilterData({
      ...filter,
      [labelId]: filterData.length
        ? filterData?.map((val) => val?.id)
        : undefined,
    });
    setCloseOption(false);
  };

  useEffect(() => {
    setSortOrder(order);
    if (labelId === "orderBy") {
      setPersonName(sortByDefaultOptionPlace.find((v) => v.id === orderBy));
    } else if (filter && filter[labelId]) {
      let newOptionValue = filter[labelId]?.map((v, index) =>
        optionValue.find((option) => option.id === v)
      );
      setPersonName(
        typeof newOptionValue === "string"
          ? newOptionValue.split(",")
          : newOptionValue
      );
      setFilterData(newOptionValue);
    }
  }, [optionValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="ptselect-form">
      <FormControl>
        {/* {labelId === "orderBy" ?
          <img
            src={sortOrder === 'ASC' ? ArrowUpIcon : ArrowDownIcon}
            alt="Icon"
            className='mr-1 h-6 w-6 p-1 cursor-pointer'
            onClick={handleSort}
          /> : null} */}
        <Select
          open={closeOption}
          onOpen={() => setCloseOption(true)}
          onClose={() => setCloseOption(false)}
          className={className ? className : null}
          id={id ? id : null}
          displayEmpty
          multiple={multiple ? multiple : null}
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{labelValue}</em>;
            }
            return labelId === "orderBy"
              ? `Sort by: ${
                  selected.length
                    ? selected?.map((val) => val?.name).join(", ")
                    : selected?.name
                }`
              : selected.length
              ? selected?.map((val) => val?.name).join(", ")
              : selected?.name;
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          {optionValue?.map((optionValue) => (
            <MenuItem
              key={optionValue?.id}
              value={optionValue}
              sx={{ width: labelId === "regionId" ? 280 : undefined }}
            >
              {checked && (
                <Checkbox
                  checked={
                    personName
                      ?.map((val) => val?.name)
                      .indexOf(optionValue?.name) > -1
                  }
                />
              )}
              <ListItemText primary={optionValue?.name} />
            </MenuItem>
          ))}
          {Isbutton && (
            <div className="btn-wrapper">
              <PTButton
                label="Clear"
                className="normal-case fill-gray btn"
                size="large"
                handleOnClickEvent={() => handleClearFilter()}
              />
              <PTButton
                label="Apply"
                className={`normal-case fill-blue btn`}
                size="large"
                handleOnClickEvent={() => applyFilteredData()}
              />
            </div>
          )}
        </Select>
        {labelId === "orderBy" ? (
          <img
            src={sortOrder === "ASC" ? ArrowUpIcon : ArrowDownIcon}
            alt="Icon"
            className="mr-1 h-6 w-6 p-1 cursor-pointer"
            onClick={handleSort}
          />
        ) : null}
      </FormControl>
    </div>
  );
}
