export const apiTimeoutTime = 300000;

export const proptchDetailsTab = [
  "overview",
  "market", //offering
  "numbers", // track record
  "marketInsights",
  "deployAndUse",
  "designReview",
  "companyProfile",
];

export const proptechDetailsArray = [
  "overviewDetails",
  "marketDetails",
  "numbersDetails",
  "marketInsightsDetails",
  "deployAndUseDetails",
  "designReviewDetails",
  "companyProfileDetails",
];

export const sortByDefaultOptionPlace = [
  { id: "createdAt", name: "Default", labelId: "orderBy" },
  { id: "recentlyAdded", name: "Recently added", labelId: "orderBy" },
  { id: "region", name: "Region", labelId: "orderBy" },
  // { id: "stage", name: "Stage", labelId: "orderBy" },
  // { id: "revenue", name: "Revenue", labelId: "orderBy" },
];

export const ChangePlanModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 1000,
  width: "85%",
  maxHeight: "90%",
  backgroundColor: "#E5E5E5",
  border: "2px solid #E5E5E5",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
  overflow: "auto",
};

export const FORM_FIELDS = {
  passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
  passwordNotMatch: "Password don't match",
  sizeLimit: {
    tooShortPassword: "Password is too short - should be 6 chars minimum.",
    passwordContain:
      "Password has contain minimum 6 characters (includes 1 Upper, 1 Special, 1 Number)",
  },
  currentPassword: "currentPassword",
  currentPassword2: "Current Password",
  password: "password",
  required: "Required",
  confirmPassword: "confirmPassword",
  confirmPassword2: "Confirm Password",
  newPassword: "New Password",
  confirmNewPassword: "Confirm new password",
  notRegistered: "Not registered yet?",
  createAccount: "Create an account",
  rememerMe: "Remember me",
  forgotPassword: "Forgot password?",
  logIn: "Log in",
  emailValidFormat: "This email is not a valid format.",
  signUp: "Sign up",
  securitySeriously: `We take security seriously. We'll verify your details once completed, and be in contact via email, ASAP.`,
  mustContain: "Password must contain at least:",
  upperCase: "1 uppercase letter",
  number: "1 number",
  special: "1 special character",
  alreadyAccount: "Already got an account?",
};

export const PROPTECHCARD = {
  // goodFit: "Why is this a good fit",
  goodFit: "Key points",
  curated: "Curated for you",
  keyCustomers: "KEY PARTNERS",
  sectors: "SECTORS",
};

export const SHARE_MODAL = {
  share: "Share",
  shareLink: "Or share this link",
  shareMsg: "Hey, check out this proptech. Keen to hear what you think!",
};
export const MANAGEUSERS_MODAL_HEADER = "Manage users";

export const INVITE_MODAL = {
  emailAddressPlaceholder:
    "Only users with your company email can join your team",
  invitationMessage: "Hey, come join this awesome platform!",
};

export const ICON_PLACEHOLDER_STYLE = {
  FeatureKeyFeatureLabel: { width: "30px", height: "30px" },
  KeyFeatureLabel: { width: "44px", height: "40px" },
  VerticalLogoWithHeading: {
    width: "40px",
    height: "40px",
    marginBottom: "12px",
  },
  KeySpanLogo: { width: "46px", height: "52px" },
};

export const Radar_Chart_Labels = {
  overview: "Use cases",
  offering: "Points of difference",
  trackRecord: "Radar",
  deployAndUse: "Points of difference",
  techReview: "Product integration",
};

// need to update video url once received from client
export const INTRODUCTRY_VIDEO_URL =
  "https://ptc-company.s3.ap-southeast-2.amazonaws.com/proptech_explainer_rff.mp4";

export const FREE_MEMBERSHIP_MEETING_COUNT = 20;

export const WELCOME_POP_UP_COUNT = 2;

export const features = [
  { icon: false, key: "durationOfDays", label: "Duration (in days)" },
  {
    icon: true,
    key: "rffPitchAnalysedAndCurated",
    label: "RFF pitch analysed and curated",
  },
  {
    icon: true,
    key: "accessToGlobalPTCNetwork",
    label: "Access to The PTC Global Network",
  },
  { icon: true, key: "profilePublished", label: "Profile published" },
  // { icon: true, key: "integratedCalendar", label: "Integrated calendar" },
  // {
  //   icon: true,
  //   key: "videoHostedOnPTCPlatform",
  //   label: "Video hosted on the PTC platform",
  // },
  {
    icon: false,
    key: "numberOfWatchListCompanies",
    label: "Watchlist companies",
  },
  { icon: true, key: "isHavingForYouFunctionality", label: "For You" },
  { icon: true, key: "isHavingDownloadOnePager", label: "Download one pager" },
  { icon: true, key: "isHavingDesignReview", label: "Design review" },
  { icon: true, key: "isHavingMarketInsight", label: "Market insights" },
];

export const projectPageTabs = [
  "Company Overview",
  "Strategy Diagnostic",
  "Assessment Criteria",
  "Transformation",
  "Implementation",
  "Adoption",
  "Attachments",
];

export const CHANGES_MESSAGE = {
  // upgradePlan: "Please contact us for further engagement.",
  upgradePlan: "Contact ksa@theproptechconnection.com for more insights",
};

export const investmentSize = [
  {
    value: 0,
    label: "$0",
  },
  {
    value: 10000000,
    label: "$10m+",
  },
];
